import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/floortiles/bedroom/image.png";
import img2 from "../../images/floortiles/bedroom/image2.png";
import img3 from "../../images/floortiles/bedroom/image3.png";
import img4 from "../../images/floortiles/bedroom/image4.png";
const BedroomFloorTiles = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
        <div className="first">
        <p className="second">
          <h1>Bedroom Floor Tiles</h1>
         
            Revamp your house with an eye-catching range of bedroom floor tiles
            by CFL. Manufactured with the help of advanced digital machinery,
            the assortment of floor tiles comes in various textures, and
            dimensions to match the interior of your house. The polished tiles
            by the brand have a reflective surface that effortlessly brighten up
            the whole area. The radiance of the floor tiles is durable and easy
            to keep up. To add that earthy and rustic look of natural wood to
            your bedroom or living room, choose from our eclectic collection of
            wooden floor tiles..
          </p>
          <div >
          <img src={img1} alt="Placeholder" className="main_image" />
        </div>
        </div>
      </div>
      </div>
      <div className="container">
      
      <div className="tile">
        <div className="image">
          <img src={img2} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img3} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img4} alt="Placeholder" />
        </div>
      </div>
      {/* <div className="tile">
        <div className="image">
          <img src={img5} alt="Placeholder" />
        </div>
      </div> */}
    </div></>
  );
};

export default BedroomFloorTiles;
