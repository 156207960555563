import React from "react";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";


import img2 from "../../images/Accessories/Maintenance/Hakwood Complete Maintenance.jpg";
import img3 from "../../images/Accessories/Maintenance/Esco & Zenon Maintenance.jpg";
import img4 from "../../images/Accessories/Maintenance/General Maintenance.jpg";
import img5 from "../../images/Accessories/Maintenance/Artelit Maintenance.jpg";


// import img4 from "../images/laminates/10mm/img1.jpg";
// import img5 from "../images/laminates/10mm/img1.jpg";

const Maintenance = () => {
  const images = [
   
    {
      url: img2,
      text1: "Hakwood Complete Maintenance",
      text2: "Complete Cleaner, Oil Care and Finish Care"
    },
    {
      url:img3,
      text1: "Esco & Zenon Maintenance",
      text2: "Osmo Wash & Care, Polyx-oil, Liquid Wax and Mop Set"
    },
    {
      url: img4,
      text1: "General Maintenance",
      text2: "Monocoat Oils and Soap"
    },
    {
      url: img5,
      text1: "Artelit Maintenace",
      text2: "Artelit Glue, Primer, Adhesive, Cleaner and Spreader"
    },
  ];
  return (
    <>
      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}>
            <div className="text-overlay">
              <h2>{image.text1}</h2>
              <h3>{image.text2}</h3>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Maintenance;
