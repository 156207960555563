import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/walltiles/BathroomTiles/image.png";
import img2 from "../../images/walltiles/BathroomTiles/image5.jpg";
import img3 from "../../images/walltiles/BathroomTiles/image6.jpg";
import img4 from "../../images/walltiles/BathroomTiles/image7.jpg";
import img5 from "../../images/walltiles/BathroomTiles/image8.jpg";
import img6 from "../../images/walltiles/BathroomTiles/image9.jpg";

const BathroomWallTiles = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Bathroom Wall Tiles</h1>
              CFL introduces an exceptional selection of bathroom wall tiles, blending cutting-edge technology with artistic innovation. Transform your bathroom with our exclusive and sophisticated wall tile range. The collection includes options such as ceramic tiles, glazed vitrified tiles, and polished vitrified tiles, offering an extensive variety of bathroom tile designs.
Available in a wide spectrum of colors, patterns, and sizes, these tiles are designed to create a stunning visual impact in your space. This versatile range features both glossy and matte finishes, ensuring a perfect match for any style or preference.

            </p>
            <div >
              <img src={img1} alt="Bathroom Tile" className="main_image"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {[img2, img3, img4, img5,img6].map((image, index) => (
          <div className="tile" key={index}>
            <div className="image">
              <img src={image} alt={`Bathroom Tile ${index}`} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BathroomWallTiles;
