import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/floortiles/kitchen/image.png";
import img2 from "../../images/floortiles/kitchen/image2.png";
import img3 from "../../images/floortiles/kitchen/image3.png";
import img4 from "../../images/floortiles/kitchen/image4.png";

const KitchenFlootTiles = () => {
  return (
    <>

      <div className="tile1">
        <div className="text">
        <div className="first">
        <p className="second">
          <h1 >Kitchen Floor Tiles </h1>
          
            CFL’s grand range of exquisite kitchens floor tiles are a feast for
            eyes. The ultimate collection has the ability to transform any plain
            Jane dimension to an ultra-gorgeous one. The designer tiles are made
            using state-of-the art technology to ensure that each kitchen floor
            tile is of high quality. Our tiles are a perfect blend of
            mind-blowing designs with appealing colours and extraordinary
            finishes. The large collection is made to cater the individual
            styles and needs of our customers. Our constant quality checks and
            diligent workers ensure that we produce the best quality tiles.
          </p>
          <div >
          <img src={img1} alt="Placeholder" className="main_image" />
        </div>
        </div>
      </div>
      </div>

      <div className="container">
      <div className="tile ">
        <div className="image">
          <img src={img1} alt="Placeholder" />
        </div>
       
      </div>
      <div className="tile ">
        <div className="image">
          <img src={img2} alt="Placeholder" />
        </div>
       
      </div>

      <div className="tile">
        <div className="image">
          <img src={img3} alt="Placeholder" />
        </div>
       
      </div>

      <div className="tile">
        <div className="image">
          <img src={img4} alt="Placeholder" />
        </div>
       
      </div>
    </div></>
  );
};

export default KitchenFlootTiles;
