import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/walltiles/ExteriorTiles/image5.jpg";
import img2 from "../../images/walltiles/ExteriorTiles/image6.jpg";
import img3 from "../../images/walltiles/ExteriorTiles/image7.jpg";
import img4 from "../../images/walltiles/ExteriorTiles/image8.jpg";
import img5 from "../../images/walltiles/ExteriorTiles/image9.jpg";

const ExteriorWallTiles = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Exterior Wall Tiles</h1>
              An exceptional product emerges from unparalleled craftsmanship and innovative technology, as seen in the exterior wall tiles by Comfort Floor Tiles. Designed with precision, these tiles are versatile and can be utilized in various ways, offering you the freedom to unleash your creativity and craft a personalized space.
With their striking designs and elegant shapes, CFL exterior wall tiles combine aesthetic charm with durability, providing high resistance and a sturdy look to outer walls. These tiles make a bold statement of modern style, enhancing the visual appeal of your space with sophistication and grace.

            </p>
            <div >
              <img src={img1} alt="Exterior Tile" className="main_image"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {[img2, img3, img4, img5].map((image, index) => (
          <div className="tile" key={index}>
            <div className="image">
              <img src={image} alt={`Exterior Tile ${index}`} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ExteriorWallTiles;
