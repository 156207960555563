import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/floortiles/Bathroom/image.png";
import img2 from "../../images/floortiles/Bathroom/image2.png";
import img3 from "../../images/floortiles/Bathroom/image3.png";
import img4 from "../../images/floortiles/Bathroom/image4.png";
const BathRoomFloorTiles = () => {
  return (
    <>
    
      <div className="tile1">
        <div className="text">
        <div className="first">
        <p className="second">
          <h1 >Bathroom Floor Tiles</h1>
          
            CFL’s presents the new extravagant range of bathroom tiles that come
            with astounding features. The innovative collection of matt finish
            tiles consists of a variety of spectacular designs which will surely
            provide a sense of wideness to the area with a dash of
            sophistication & style. As matt surfaces offer better grip and
            resistance, such matt finish tiles are the evident choice for
            flooring as well as wall tiling, especially in bathrooms.
          </p>
          <div >
          <img src={img1} alt="Placeholder" className="main_image" />
        </div>
        </div>
      </div>
      </div>

      <div className="container">
      
      <div className="tile">
        <div className="image">
          <img src={img2} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img3} alt="Placeholder" />
        </div>
       
      </div>

      <div className="tile">
        <div className="image">
          <img src={img4} alt="Placeholder" />
        </div>
       
      </div>
      {/* <div className="tile">
        <div className="image">
          <img src={img5} alt="Placeholder" />
        </div>
       
      </div> */}
    </div></>
  );
};

export default BathRoomFloorTiles;
