import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/walltiles/OfficeTiles/image5.jpg";
import img2 from "../../images/walltiles/OfficeTiles/image6.jpg";
import img3 from "../../images/walltiles/OfficeTiles/image7.jpg";
import img4 from "../../images/walltiles/OfficeTiles/image8.jpg";
import img5 from "../../images/walltiles/OfficeTiles/image9.jpg";

const OfficeWallTiles = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1 >Wall Tiles for offices, hotels, restaurants, cafe etc.</h1>
              CFL excels in catering to the needs and preferences of offices, hotels, restaurants, cafes, and other commercial spaces by offering top-quality wall tiles. From minimalistic designs to lavish styles, the collection is vast, diverse, and striking.
This remarkable range features a variety of textures that seamlessly coordinate with your furniture and enhance the overall interior design. Along with their exceptional aesthetics, CFL’s are also highly durable, resilient, and low-maintenance, making them a practical and stylish choice for any commercial setting.

            </p>
            <div >
              <img src={img1} alt="Office Tile" className="main_image"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {[img2, img3, img4, img5].map((image, index) => (
          <div className="tile" key={index}>
            <div className="image">
              <img src={image} alt={`Office Tile ${index}`} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OfficeWallTiles;
