import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './menu_bar.css'; 

function MenuBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeSubDropdown, setActiveSubDropdown] = useState(null);

  const toggleMainMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setActiveDropdown(null);
    setActiveSubDropdown(null);
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    setActiveSubDropdown(null); // Close sub-dropdown when changing main dropdown
  };

  const toggleSubDropdown = (subDropdown) => {
    setActiveSubDropdown(activeSubDropdown === subDropdown ? null : subDropdown);
  };

  const handleMenuItemClick = () => {
    closeMenu(); // Close menu when a menu item is clicked
  };

  return (
    <div className='menu_content'>
      <button className="menu-button" onClick={toggleMainMenu}>
        <span className="menu-icon">☰</span>
      </button>

      {/* Sliding Menu */}
      <div className={`menu-slider ${isMenuOpen ? 'menu-expand' : ''}`}>
        <button className="close-button" onClick={closeMenu}>✖</button>
        <ul className="menu-tabs">
          <li><Link to="/" onClick={handleMenuItemClick}>Home</Link></li>
          <li><Link to="/about" onClick={handleMenuItemClick}>About Us</Link></li>
          
          <li className="menu-dropdown">
            <Link
              onClick={() => toggleDropdown('tiles')}
              className="menu-dropdown-toggle"
            >
              Tiles
            </Link>
            <ul className={`menu-dropdown-content ${activeDropdown === 'tiles' ? 'menu-expand' : ''}`}>
              <li className="menu-second-dropdown">
                <Link
                  onClick={() => toggleSubDropdown('floor')}
                  className="menu-second-dropdown-toggle"
                >
                  Floor Tiles
                </Link>
                <ul className={`menu-second-dropdown-content ${activeSubDropdown === 'floor' ? 'menu-expand' : ''}`}>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/Living_room">Living Room Floor Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/Bedroom">Bedroom Floor Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/Kitchen">Kitchen Floor Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/Outdoor">Outdoor Floor Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/Bathroom">Bathroom Floor Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/Commercial">Floor Tiles for Commercial Purpose</Link></li>
                </ul>
              </li>
              <li className="menu-second-dropdown">
                <Link
                  onClick={() => toggleSubDropdown('wall')}
                  className="menu-second-dropdown-toggle"
                >
                  Wall Tiles
                </Link>
                <ul className={`menu-second-dropdown-content ${activeSubDropdown === 'wall' ? 'menu-expand' : ''}`}>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/wall_tile_Living_room">Wall Tiles for Living Rooms</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/wall_tile_Bedroom">Bedroom Wall Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/wall_tile_Kitchen">Kitchen Wall Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/wall_tile_Exterior">Exterior Wall Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/wall_tile_Bathroom">Bathroom Wall Tiles</Link></ li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/wall_tile_offices">Wall Tiles for Offices, Hotels, Restaurants, Cafes, etc.</Link></li>
                </ul>
              </li>
              <li className="menu-second-dropdown">
                <Link
                  onClick={() => toggleSubDropdown('outdoor')}
                  className="menu-second-dropdown-toggle"
                >
                  Outdoor/Parking Tiles
                </Link>
                <ul className={`menu-second-dropdown-content ${activeSubDropdown === 'outdoor' ? 'menu-expand' : ''}`}>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/outdoor_tiles">Outdoor Tiles</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/parking_tiles">Parking Tiles</Link></li>
                </ul>
              </li>
              <li className='menu-second-dropdown-toggle' onClick={handleMenuItemClick}><Link to="/tile-accessories">Accessories</Link></li>
            </ul>
          </li>

          <li className="menu-dropdown">
            <Link
              onClick={() => toggleDropdown('waterproofspc')}
              className="menu-dropdown-toggle"
            >
              Water Proof SPC
            </Link>
            <ul className={`menu-dropdown-content ${activeDropdown === 'waterproofspc' ? 'menu-expand' : ''}`}>
              <li className='menu-second-dropdown' onClick={handleMenuItemClick}><Link to="/8mm_wps">Grizzly 5.5mm</Link></li>
              <li className='menu-second-dropdown' onClick={handleMenuItemClick}><Link to="/10mm_wps">Tiger 6mm</Link></li>
              <li className='menu-second-dropdown' onClick={handleMenuItemClick}><Link to="/12mm_wps">Lion 7mm</Link></li>
              <li className='menu-second-dropdown' onClick={handleMenuItemClick}><Link to="/stair_nosings">Stair Nosings</Link></li>
            </ul>
          </li>

          <li className="menu-dropdown">
            <Link
              onClick={() => toggleDropdown('wood')}
              className="menu-dropdown-toggle"
            >
              Wood Flooring
            </Link>
            <ul className={`menu-dropdown-content ${activeDropdown === 'wood' ? 'menu-expand' : ''}`}>
              <li className="menu-second-dropdown">
                <Link
                  onClick={() => toggleSubDropdown('laminates')}
                  className="menu-second-dropdown-toggle"
                >
                  Laminates
                </Link>
                <ul className={`menu-second-dropdown-content ${activeSubDropdown === 'laminates' ? 'menu-expand' : ''}`}>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/8mm_Laminate">8mm Laminate</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/10mm_Laminate">10mm Laminate</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/12mm_Laminate">12mm Laminate</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/14mm_Laminate">14mm Laminate</Link></li>
                </ul>
              </li>
              <li className="menu-second-dropdown">
                <Link
                  onClick={() => toggleSubDropdown('water_resistance')}
                  className="menu-second-dropdown-toggle"
                >
                  Water Resistance Laminates
                </Link>
                <ul className={`menu-second-dropdown-content ${activeSubDropdown === 'water_resistance' ? 'menu-expand' : ''}`}>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/8mm_wrl">8mm Laminate</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/10mm_wrl">10mm Laminate</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/12mm_wrl">12mm Laminate</Link></li>
                </ul>
              </li>
              <li className="menu-second-dropdown">
                <Link
                  onClick={() => toggleSubDropdown('hardwoods')}
                  className="menu-second-dropdown-toggle"
                >
                  Hard Woods
                </Link>
                <ul className={`menu-second-dropdown-content ${activeSubDropdown === 'hardwoods' ? 'menu-expand' : ''}`}>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/8mm_hardwoods">8mm Laminate</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/10mm_hardwoods">10mm Laminate</Link></li>
                  <li className='menu-second-dropdown-subcontent' onClick={handleMenuItemClick}><Link to="/12mm_hardwoods">12mm Laminate</Link></li>
                </ul>
              </li>
              <li className='menu-second-dropdown-toggle' onClick={handleMenuItemClick}><Link to="/wood_accessories">Accessories</Link></li>
            </ul>
          </li>

          <li><Link to="/carpet" onClick={handleMenuItemClick}>Carpet</Link></li>
          <li><Link to="/bathroom_refurbishments" onClick={handleMenuItemClick}>Bathroom Refurbishments</Link></li>
          <li><Link to="/our_projects" onClick={handleMenuItemClick}>Our Projects</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default MenuBar;