import React, { useState } from 'react';
import './Navbar.css';
import logo from './images/logo.png';
import { MapPin, Phone, Mail} from 'lucide-react';
import logo1 from './images/whatsapp-icon.png';
import { Link } from 'react-router-dom';
import MenuBar from './menu_bar';


function Navbar() {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  return (
    <div className="navbar">
      <div className="navbar-top">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <p className="title">
            <p className="company-name C">
              Comfort <span className="F">Floors &</span>
            </p>
            <p className="company-name L">
              <span className="F">Bathrooms</span> Limited
            </p>
            
          </p>

        </div>
        <MenuBar />
        <a
          href="https://maps.app.goo.gl/fKKoAhKrKsdcdDmbA"
          target="_blank"
          rel="noopener noreferrer"
          className="company-address"
        >
          <MapPin size={18} className="icon" />
          <div>
            <h6 className="address_title">Address</h6>
            <p className="address">Unit D1, Kylemore Factory Estate,</p>
            <p className="address">Kylemore Rd, Kylemore,Dublin 10,</p>
           <p>  D10 NV96</p>
            
          </div>
        </a>
        <div className="contact_main">
          <a href="tel:+353857139964" className="contact">
            <Phone size={19} className="icon" />
            <p className="mobile_num"><b>+353 85 713 9964</b></p>
          </a>
          <a
            href="https://wa.me/353857139964"
            target="_blank"
            rel="noopener noreferrer"
            className="contact"
          >
            <img src={logo1} alt="whatsapp-icon" width="30" height="30" />
            <p className="mobile_num"><b>+353 85 713 9964</b></p>
          </a>
          <a href="mailto:info@comfortfloors.ie" className="contact1">
            <Mail size={35} className="icon" />
            <p className="mobile_num1"><b>info@comfortfloors.ie</b></p>
          </a>
         
        </div>
      </div>

      {/* Tabs */}
      <ul className="navbar-tabs">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li className="dropdown">
          <Link
            onClick={() => toggleDropdown('tiles')}
            className="dropdown-toggle"
          >
            Tiles
          </Link>
          <ul className={`dropdown-content ${activeDropdown === 'tiles' ? 'expand' : ''}`}>
            <li className="second-dropdown">
              <Link
                onClick={() => toggleDropdown('floor')}
                className="second-dropdown-toggle"
              >
                Floor Tiles
              </Link>
              <ul className={`second-dropdown-content ${activeDropdown === 'floor' ? 'expand' : ''}`}>
                <li><Link to="/Living_room">Living Room Floor Tiles</Link></li>
                <li><Link to="/Bedroom">Bedroom Floor Tiles</Link></li>
                <li><Link to="/Kitchen">Kitchen Floor Tiles</Link></li>
                <li><Link to="/Outdoor">Outdoor Floor Tiles</Link></li>
                <li><Link to="/Bathroom">Bathroom Floor Tiles</Link></li>
                <li><Link to="/Commercial">Floor Tiles for Commercial Purpose</Link></li>
              </ul>
            </li>
            <li className="second-dropdown">
              <Link
                onClick={() => toggleDropdown('wall')}
                className="second-dropdown-toggle"
              >
                Wall Tiles
              </Link>
              <ul className={`second-dropdown-content ${activeDropdown === 'wall' ? 'expand' : ''}`}>
                <li><Link to="/wall_tile_Living_room">Wall Tiles for Living Rooms</Link></li>
                <li><Link to="/wall_tile_Bedroom">Bedroom Wall Tiles</Link></li>
                <li><Link to="/wall_tile_Kitchen">Kitchen Wall Tiles</Link></li>
                <li><Link to="/wall_tile_Exterior">Exterior Wall Tiles</Link></li>
                <li><Link to="/wall_tile_Bathroom">Bathroom Wall Tiles</Link></li>
                <li><Link to="/wall_tile_offices">Wall Tiles for Offices, Hotels, Restaurants, Cafes, etc.</Link></li>
              </ul>
            </li>
            <li className="second-dropdown">
              <Link
                onClick={() => toggleDropdown('outdoor')}
                className="second-dropdown-toggle"
              >
                Outdoor/Parking Tiles
              </Link>
              <ul className={`second-dropdown-content ${activeDropdown === 'outdoor' ? 'expand' : ''}`}>
                <li><Link to="/outdoor_tiles">Outdoor Tiles</Link></li>
                <li><Link to="/parking_tiles">Parking Tiles</Link></li>
              </ul>
            </li>
            <li><Link to="/tile-accessories">Accessories</Link></li>
          </ul>
        </li>
        <li className="dropdown">
          <Link
            onClick={() => toggleDropdown('waterproofspc')}
            className="dropdown-toggle"
          >
            Water Proof SPC
          </Link>
          <ul className={`dropdown-content ${activeDropdown === 'tiles' ? 'expand' : ''}`}>
            <li className="second-dropdown">
            <li><Link to="/8mm_wps">Grizzly 5.5mm</Link></li>
                <li><Link to="/10mm_wps">Tiger 6mm</Link></li>
                <li><Link to="/12mm_wps">Lion 7mm</Link></li>
                <li><Link to="/stair_nosings">stair Nosings</Link></li>
              
            </li>
            
          </ul>
        </li>
        <li className="dropdown">
          <Link
            onClick={() => toggleDropdown('wood')}
            className="dropdown-toggle"
          >
            Wood Flooring
          </Link>
          <ul className={`dropdown-content ${activeDropdown === 'wood' ? 'expand' : ''}`}>
            <li className="second-dropdown">
              <Link
                onClick={() => toggleDropdown('floor')}
                className="second-dropdown-toggle"
              >
                Laminates
              </Link>
              <ul className={`second-dropdown-content ${activeDropdown === 'floor' ? 'expand' : ''}`}>
                <li><Link to="/8mm_Laminate">8mm Laminate</Link></li>
                <li><Link to="/10mm_Laminate">10mm Laminate</Link></li>
                <li><Link to="/12mm_Laminate">12mm Laminate</Link></li>
                <li><Link to="/14mm_Laminate">14mm Laminate</Link></li>
              </ul>
            </li>
            <li className="second-dropdown">
              <Link
                onClick={() => toggleDropdown('floor')}
                className="second-dropdown-toggle"
              >
                Water Resistance Laminates
              </Link>
              <ul className={`second-dropdown-content ${activeDropdown === 'floor' ? 'expand' : ''}`}>
                <li><Link to="/8mm_wrl">8mm Laminate</Link></li>
                <li><Link to="/10mm_wrl">10mm Laminate</Link></li>
                <li><Link to="/12mm_wrl">12mm Laminate</Link></li>
              </ul>
            </li>
            
            <li className="second-dropdown">
              <Link
                onClick={() => toggleDropdown('floor')}
                className="second-dropdown-toggle"
              >
                Hard Woods
              </Link>
              <ul className={`second-dropdown-content ${activeDropdown === 'floor' ? 'expand' : ''}`}>
                <li><Link to="/8mm_hardwoods">14mm Hardwoods</Link></li>
                <li><Link to="/10mm_hardwoods">18mm Hardwoods</Link></li>
                <li><Link to="/12mm_hardwoods">20mm Hardwoods</Link></li>
                <li><Link to="/hardwoods_herringbone">Hardwoods Herringbone</Link></li>
              </ul>
            </li>
            <li className="second-dropdown">
              <Link
                onClick={() => toggleDropdown('floor')}
                className="second-dropdown-toggle"
              >
                Accessories
              </Link>
              <ul className={`second-dropdown-content ${activeDropdown === 'floor' ? 'expand' : ''}`}>
                <li><Link to="/trims">Trims & Profiles</Link></li>
                <li><Link to="/underlays">Underlays</Link></li>
                <li><Link to="/Maintenance">Maintenance</Link></li>
                
              </ul>
            </li>
          </ul>
        </li>
        <li><Link to="/carpet">Carpet</Link></li>
        <li><Link to="/bathroom_refurbishments">Bathroom Refurbishments</Link></li>
        <li><Link to="/our_projects">Our Projects</Link></li>
      </ul>
    </div>
  );
}

export default Navbar;
