import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/walltiles/KitchenTiles/image5.jpg";
import img2 from "../../images/walltiles/KitchenTiles/image6.jpg";
import img3 from "../../images/walltiles/KitchenTiles/image7.jpg";
import img4 from "../../images/walltiles/KitchenTiles/image8.jpg";
import img5 from "../../images/walltiles/KitchenTiles/image9.jpg";

const KitchenWallTiles = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Kitchen Wall Tiles</h1>
              CFL offers a stunning collection of kitchen ceramic wall tiles, crafted to delight design enthusiasts. Each tile is created using state-of-the-art technology, ensuring designs that are not only visually striking but also robust and long-lasting. Rigorous quality checks guarantee that every tile delivered to our customers meets the highest standards.
The designer kitchen tiles feature captivating patterns and vibrant color palettes, designed to leave a lasting impression. From timeless classics to contemporary styles, our versatile range caters to diverse preferences, allowing customers to achieve a personalized look for their kitchen spaces.

            </p>
            <div >
              <img src={img1} alt="Kitchen Tile" className="main_image"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {[img2, img3, img4, img5].map((image, index) => (
          <div className="tile" key={index}>
            <div className="image">
              <img src={image} alt={`Kitchen Tile ${index}`} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default KitchenWallTiles;
