import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-us-content">
      <h1>About Us</h1>
      <p className="intro-text">
        <strong className='about_intro_text'>Comfort Floors & Tiles</strong> provides premium carpet, wood, and tile flooring services across Dublin, Ireland, and nearby counties. With over 8 years of experience, we specialize in installing high-quality flooring for residential and commercial spaces, offering top brands and expert craftsmanship. Our services include free estimates and measuring, ensuring a seamless experience every day of the week. As a vibrant, customer-focused brand, we prioritize style, sustainability, and satisfaction in every project.
      </p>
        <div className="about-us-section">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3utQIXukEJGclu4hL8b70VyTH5wGt-JHf4g&s"
            alt="Wood Flooring"
            className="about-us-image"
          />
          <div className="about-us-text">
            <h2>Wood Flooring</h2>
            <p>
            We offer premium wood flooring installations, combining elegance and durability for homes and businesses. With top brands and styles, our expert solutions ensure a perfect fit for your space’s aesthetic and longevity.
            </p>
          </div>
        </div>
        <div className="about-us-section">
          <img
            src="https://www.shaktiplasticinds.com/wp-content/uploads/2023/07/Tiles.jpg"
            alt="Tiles"
            className="about-us-image"
          />
          <div className="about-us-text">
            <h2>Tiles</h2>
            <p>
            Our meticulously crafted tiles blend beauty and functionality, offering diverse textures, patterns, and colors. Designed for durability and elegance, they elevate any space, from sleek modern interiors to timeless classic designs.
            </p>
          </div>
        </div>
        <div className="about-us-section">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRfn4UJuCqAe1lX32hKUnuqk5iAJvF1AJgHg&s"
            alt="Carpets"
            className="about-us-image"
          />
          <div className="about-us-text">
            <h2>Carpets</h2>
            <p>
            Experience unmatched comfort with our premium carpets, combining warmth, elegance, and durability. Crafted from high-quality materials in stylish designs, they beautifully complement every room, enhancing both home and office interiors.
            </p>
          </div>
        </div>
        <p className="cta">
          <strong>Contact us today</strong> for unbeatable deals on wood flooring, tiles, and carpets!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
