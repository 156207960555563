import React from "react";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css"
import img1 from "../../images/floortiles/LivingRoom/image.png";
import img2 from "../../images/floortiles/LivingRoom/image2.png";
import img3 from "../../images/floortiles/LivingRoom/image3.png";
import img4 from "../../images/floortiles/LivingRoom/image4.png";
// import img5 from "../../images/floortiles/LivingRoom/image3.png";

const LivingRoomFloorTiles = () => {
  const images = [
  
    {
      url: img2,
      text: "Experience the Wonder"
    },
    {
      url:img3,
      text: "Explore the Unknown"
    },
    {
      url:img4,
      text: "Cherish the Moments"
    }
  ];
  return (
    <>
    <div className="tile1">
        <div className="text">
          
          <div className="first">
          <p className="second">
          <h1>Living Room Floor Tiles</h1>
          CFL's exclusive ceramic and vitrified floor tiles elevate the beauty of your living room with a blend of durability and elegance. Available in various colors, textures, and sizes, these designer tiles cater to every style preference, from classic to modern. Crafted with cutting-edge technology, they redefine aesthetics and add sophistication to your space. CFL tiles ensure long-lasting beauty and comfort, transforming your living room into a cozy, inviting haven. With superior quality and innovative designs, they bring your dream living room to life.
          </p>
          <div >
          <img src={img1} alt="Living Room Tile 1" className="main_image" />
        </div>
        </div>
        </div>
      </div>
      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
         
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}>
            <div className="text-overlay">
              <h2>{image.text}</h2>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default LivingRoomFloorTiles;
