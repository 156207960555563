import React, { useState } from 'react';
import './App.css';

const Card = ({ videoName, onClick }) => {
  return (
    <div className="card" onClick={() => onClick(videoName)}>
      <video className="card-video">
        <source src={`/videos/${videoName}.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const App = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (videoName) => {
    setSelectedVideo(videoName);  // Show the selected video in full screen
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);  // Close the full-screen video
  };

  return (
    <div className="app-container">
        <h1 className='bathroom_title'>Bathroom Refurbishments</h1>
      <div className="cards-container">
        {/* <Card videoName="1" onClick={handleVideoClick} /> */}
        <Card videoName="2" onClick={handleVideoClick} />
        <Card videoName="3" onClick={handleVideoClick} />
      </div>

      {selectedVideo && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <video className="modal-video" autoPlay>
              <source src={`/videos/${selectedVideo}.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button className="close-btn" onClick={handleCloseModal}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
