import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Carpet.css";

// Static product data
const products = [
  {
    id: "1",
    name: "Babylon",
    category: ["Carpet","Plain Carpet"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Babylon.png",
    description: "Babylon is soft and beautiful carpet range with an extensive selection of rich, warm colours..",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Blueberry.png", // Red
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Bone.png", // Blue
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Cloud.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Coin.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Linen.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Mist.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Nickel.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Peanut.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Pewter.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/02/Sonic.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Stone.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Tortilla.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Windsor.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Wood.png",
    ],
  },
  {
    id: "2",
    name: "Boulevard",
    category: ["Carpet","Plain Carpet"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2021/05/boulevard.jpg",
    description: "Ideal for all commercial business spaces subject to heavy traffic. Ideal for new installations and renovation, for the office, business and the hospitality trade.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_cobalt.jpg", // Red
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_dolmen.jpg", // Blue
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_dragon_fly.jpg", // Green
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_oyster.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_petrol_blue.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_pigeon.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/boulevard_templar_black.jpg",
    ],
  },
  {
    id: "3",
    name: "Boulevard Tweed",
    category: ["Carpet","Plain Carpet"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2021/05/boulevard_tweed.jpg",
    description: "Ideal for heavy domestic/commercial spaces.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_denim.jpg", // Red
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_french_grey.jpg", // Blue
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_grainstore.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_graphic_grey.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_gull_grey.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_harbour_blue.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_red_pepper.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/boulevard_tweed_tobacco_plant.jpg",
    ],
  },
  {
    id: "4",
    name: "Eclipse",
    category: ["Carpet","Plain Carpet"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2021/05/eclipse.jpg",
    description: "Eclipse offers a broad collection of colours on a classic cut pile",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2023/04/Aquamarine.jpg", // Red
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Avacado.jpg", // Blue
      "https://signaturefloors.ie/wp-content/uploads/2023/04/Champagne.jpg", // Green
      "https://signaturefloors.ie/wp-content/uploads/2023/04/Dusk.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2023/04/Screenshot-2024-05-29-103716.png",
      "https://signaturefloors.ie/wp-content/uploads/2023/04/Navy.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/Screenshot-2024-05-29-103817.png",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Pebble.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/Raffia-1.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/Raven-1.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/Rustic.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/Sandstone.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/Scarlet-1.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/Wheatfield.jpg",

    ],
  },
  {
    id: "5",
    name: "Fantasy",
    category: ["Carpet","Plain Carpet"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2021/05/fantasy.jpg",
    description: "A modern collection cut pile carpet, that is also durable and suits any area of the home.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Almond-Cream_new-600x600.jpg", // Red
      "https://signaturefloors.ie/wp-content/uploads/2021/09/ash_grey.jpg", // Blue
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Dove_new-600x600.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/mist.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/otter.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Oyster_new-600x600.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/09/pepper.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Putty_new-600x600.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Snowstorm_new-600x600.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Shadow-1-600x600.jpg",
    ],
  },
  {
    id: "6",
    name: "Firenze Brushstrokes",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2022/03/Blonde-Haze.jpg",
    description: "The Firenze collection features two designs; the dramatic artist’s palettes of Blonde Haze, Mineral, and Monochrome as well as the striking dappled effect of Burnt Umber, Marble and Titanium",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2022/03/blonde_haze.jpg", // Red
      "https://signaturefloors.ie/wp-content/uploads/2022/03/mineral.jpg", 
      "https://signaturefloors.ie/wp-content/uploads/2022/03/monochrome.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/burnt_umber.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/titanium.jpg",
    ],
  },
  {
    id: "7",
    name: "Firenze Chevron",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2018/04/firenze_chevron_intro_bright.jpg",
    description: "Ideal for heavy domestic/commercial spaces.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2022/03/midnight_gold.jpg", // Red
      "https://signaturefloors.ie/wp-content/uploads/2022/03/regal_red.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/toasted_almond.jpg", // Green
      "https://signaturefloors.ie/wp-content/uploads/2018/04/barley-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2018/04/nickel-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/04/sandstone-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2018/04/denim-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/04/space.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/04/winter_sky.jpg",
    ],
  },
  {
    id: "8",
    name: "Firenze Chic",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/woocommerce-placeholder-600x600.png",
    description: "Firenze Chic combines a marbled background with an abstract linear effect to create a truly modern design. Combined with a beautiful colour bank, Chic really lives up to its name.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Mystic.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Olive.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Shard.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Sunlit.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Terra.png",
    ],
  },
  {
    id: "9",
    name: "Firenze Edwardian",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/woocommerce-placeholder-600x600.png",
    description: "Firenze Edwardian is modern take on the classic leaf motif in carpet. Subtle lines in the design and a bank of beautiful colours help to elevate Firenze Edwardian to suit any interior.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Ash.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Bordeaux.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Flare.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Mist.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Moss.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Swell.png",
      
      
    ],
  },
  {
    id: "10",
    name: "Firenze Grids",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2021/05/firenze_grids_intro-min.jpg",
    description: "The grids range uses irregular, often intersecting, straight lines to form a non-symmetric pattern that creates a striking effect along the floor.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2022/03/copper-grey.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/cosmic_blue.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/pebble.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/red_pepper.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/caramel-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/cinder_grey-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/cobalt_blue-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/cream_sage-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/onyx-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/rosebud-min.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/06/danube.jpg",

    ],
  },
  {
    id: "11",
    name: "Firenze Marrakesh",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2021/06/marrakesh_intro_stairs.jpg",
    description: "The collection is heavily influenced by the textiles and architecture of Northern Africa. A historical, geometric Moorish design is fused with a modern colour palette to create a carpet that feels traditional yet contemporary.",
    colorOptions: [
     "https://signaturefloors.ie/wp-content/uploads/2021/05/marrakesh_storm_grey.jpg",
     "https://signaturefloors.ie/wp-content/uploads/2021/05/marrakesh_wedgewood.jpg",
     "https://signaturefloors.ie/wp-content/uploads/2021/06/marrakesh_azure.jpg",
     "https://signaturefloors.ie/wp-content/uploads/2021/06/marrakesh_cool_grey.jpg",
     "https://signaturefloors.ie/wp-content/uploads/2021/06/candy_cane.jpg",
     "https://signaturefloors.ie/wp-content/uploads/2021/06/ruby.jpg",
    ],
  },
  {
    id: "12",
    name: "Firenze Metro",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/woocommerce-placeholder-600x600.png",
    description: "Firenze Metro has a distressed geometric design with an urban, concrete twist. The subtlety of the design and colours used ensures the carpet catches the eye without overpowering any space.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Creme.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Dune.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Khaki.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Obsidian.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Onyx.png",
    ],
  },
  {
    id: "13",
    name: "Firenze Mosaic",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2022/03/mosaic_intro.jpg",
    description: "The Mosaic range fuses together two contrasting square designs that, when combined, create a striking, large symmetric pattern across the floor.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2021/05/portland.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/Redwood.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/shadow.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/tempist.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/midnight_blue.png",
    ],
  },
  {
    id: "14",
    name: "Firenze Patchwork",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2022/03/Patchwork-Bronze-Slate.jpg",
    description: "Firenze Pixel is modern take on the classic basket weave design. Subtle lines in the design and a bank of beautiful colours help to elevate Firenze Pixel to suit any interior.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2022/03/atlantic_blue.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/bronze_slate.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/clover.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/dusty_blue.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/ivory_tusk.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2022/03/seaspray.jpg",
    ],
  },
  {
    id: "15",
    name: "Firenze Pixel",
    category: ["Carpet","Firenze"],
    image:
    "https://signaturefloors.ie/wp-content/uploads/woocommerce-placeholder-600x600.png",
    description: "Firenze Pixel is modern take on the classic basket weave design. Subtle lines in the design and a bank of beautiful colours help to elevate Firenze Pixel to suit any interior.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Creme.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Dune.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Khaki.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Obsidian.png",
      "https://signaturefloors.ie/wp-content/uploads/2024/05/Onyx.png"
    ],
  },
  {
    id: "16",
    name: "Firenze Wave",
    category: ["Carpet","Firenze"],
    image:
      "https://signaturefloors.ie/wp-content/uploads/2021/05/wave_graphite_intro.jpg",
    description: "This collection pays homage to the ripples and flow of the ocean, recreating the lapping of waves by using contrasting colours to create movement across a stunning modern carpet.",
    colorOptions: [
      "https://signaturefloors.ie/wp-content/uploads/2021/05/graphite.jpg",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/aquatint.png",
      "https://signaturefloors.ie/wp-content/uploads/2021/05/steel.jpg",
    ],
  },
      {
      id: "17",
      name: "Firenze Weave",
      category: ["Carpet", "Firenze"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2022/03/weave_intro.png",
      description:
        "The Weave range features a regular, continuous pattern that uses two contrasting colours to create a sense of flow along the length of the carpet.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2021/05/graphite.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/05/aquatint.png",
        "https://signaturefloors.ie/wp-content/uploads/2021/05/steel.jpg",
      ],
    },
    {
      id: "18",
      name: "Golden Beach",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2023/06/golden_beach_intro.jpg",
      description:
        "A luxurious Saxony carpet that will elevate the comfort level underfoot for any home.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2023/06/Dove.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/06/Silver-Birch.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/06/Thresher.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/06/Tumbled-Marble.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/06/Wolfhound-Grey.jpg",
      ],
    },
    {
      id: "19",
      name: "Harmonia",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2023/04/harmonia_intro.jpg",
      description:
        "A luxurious Saxony carpet that will elevate the comfort level underfoot for any home.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Alpaca.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Apron-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Aqua.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Biscuit.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Clay-Beige.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Hampton-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Inkwell.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Oxford-Blue.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Peacock-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Pecan-Black.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Silver-Lining.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Soft-Chestnut.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Spring-Pebble-Grey.jpg",
      ],
    },
    {
      id: "20",
      name: "Hyatt Elite",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2021/05/kempinski.jpg",
      description:
        "The Hyatt collections of Supreme and Elite will harmonise and coordinate with any chosen interior colour scheme. The Hyatt collections use 100% recycled nylon yarn.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Admiral.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Cream.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Dune.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Fawn.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Grey-Cloud.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Haze.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Ochre.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Pewter.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Rope.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Silver-Frost.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Slate.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Steel-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Storm.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Twilight.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Vanilla.jpg",
      ],
    },
    {
      id: "21",
      name: "Hyatt Supreme",
      category: ["Carpet", "Plain Carpet"],
      image: "https://signaturefloors.ie/wp-content/uploads/2021/05/marriott.jpg",
      description:
        "The Hyatt collections of Supreme and Elite will harmonise and coordinate with any chosen interior colour scheme. The Hyatt collections use 100% recycled nylon yarn.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Admiral.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Cream.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Dune.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Fawn.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Grey-Cloud.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Haze.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Ochre.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Pewter.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Rope.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Silver-Frost.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Slate.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Steel-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Storm.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Twilight.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Vanilla.jpg",
      ],
    },
    {
      id: "22",
      name: "Moderna",
      category: ["Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2024/05/sidewalk-4-600x600.webp",
      description:
        "Moderna is a trend-setting patterned carpet with modern abstract designs that can help create a distinctive style to any interior.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Reflect-aka-Light-Grey-Navy.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Sidewalk-aka-Light-Grey-Dark-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Vintage-aka-Light-Beige-Beige.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Moderna-Cloud-Cracked-Stone.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Moderna-Cloud-Woodland-Trail.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Modrerna-Cloud-Coastal-Shore.png",
      ],
    },
    {
      id: "23",
      name: "Object Rib",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2021/05/object_rib.jpg",
      description:
        "A sturdy, hard-wearing carpet that is perfect for commercial communal areas and hallways.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2021/09/autumn.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/frost_grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/greystone.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/mulberry.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/indigo.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/orion_blue.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/navy-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/beige.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/05/object_rib.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/corn_black.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/denim.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/red.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/sage.jpg",
      ],
    },
    {
      id: "24",
      name: "Olympia",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2023/04/olympia_intro.jpg",
      description:
        "A high quality tufted carpet that will help modernise any home.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Batch-Loaf.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Cream-Tea.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Grey-Nuance.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Cadet-Blue.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Dawn-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Dapple-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Grey-Wolf.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Parisian-Taupe.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Savon-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/Sidewalk-Grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2023/04/olympia_intro.jpg",
      ],
    },
    {
      id: "25",
      name: "Opulence",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2024/02/opulence-600x600.webp",
      description:
        "Opulence is a beautiful carpet range with a carefully selected modern colour palette to help add luxuriousness to any home.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2024/02/260-Ash.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/261-Light-Taupe.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/262-Vanilla.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/264-Dune.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/265-Aspen.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/272-Pistachio.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/270-Glacier.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/269-Slate.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/02/273-Latte.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/351-Clay.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/357-Rock-Salt.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/358-Cristal.png",
      ],
    },
    {
      id: "26",
      name: "Revive Oasis",
      category: ["Carpet", "Revive"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Revive-Elevate-Heron-Island-sitting-room-600x600.webp",
      description:
        "Revive is a new range of Eco-Carpets where each square metre save up to 1.2kg of plastic waste polluting our oceans or reaching landfill.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2024/05/CALM-CLOUD.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/FOXGLOVE.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/HEATHCLIFF.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/IRISH-ELK.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/MIDNIGHT-MIST.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/MORNING-AIR.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/NORDIC-BREEZE.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/STORM.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/SALTED-CARAMEL.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/SPLIT-STONE.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/SEA-SMOKE.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/WICKLOW-WAY.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/WARM-SANDS.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/WINTER-TRUFFLE.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/WOLF-HOUND.png",
      ],
    },
    {
      id: "27",
      name: "Revive Patterns",
      category: ["Carpet", "Revive"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2021/05/boulevard_tweed.jpg",
      description:
        "Revive is a new range of Eco-Carpets where each square metre save up to 1.2kg of plastic waste polluting our oceans or reaching landfill.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2024/05/ABSTARCT-POLISHED-COTTON.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/ABSTRACT-DRAGONFLY-WING.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/CASABLANCA-MYSTIC-FOG.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/CASABLANCA-INKWELL.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/CASABLANCA-PANTRY-BLUE.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/CASABLANCA-MYSTIC-FOG.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/ELEVATE-DEJAVU.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/ELEVATE-HERON-ISLAND.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/GRECIAN-OYSTER-BED.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/ELEVATE-SILK-SEAL.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/GRECIAN-PEACOCK-BLUE.png",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/GRECIAN-TREACLE-PUDDING.png",
      ],
    },
    {
      id: "28",
      name: "Velvet Bliss",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Grey-staircase-1.png",
      description:
        "A luxurious Velvet carpet that will elevate the comfort level underfoot for any home.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2024/05/72-Beach.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/73-Fossil.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/91-Ganache.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/420-Garnet.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/450-Saffron.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/473-Honey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/474-Fog.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/490-Pearl.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/476-Granite.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/471-Almond.jpg",
      ],
    },
    {
      id: "29",
      name: "Velvet Deluxe",
  
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2021/05/velvet_deluxe.jpg",
      description:
        "This carpet is made on Tenth Gauge machinery to give a fine dense pile surface that’s ideal for anywhere around the home.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2021/09/atlantis.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/burgundy.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/frappe.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/frost.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/grey_mist.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/licorice.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/mushroom.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/nordic_grey.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/oyster-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/putty-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/shingle.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2021/09/silver_pearl.jpg",
      ],
    },
    {
      id: "30",
      name: "Velvet Touch",
      category: ["Carpet", " Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2022/12/velvet_touch-intro.jpg",
      description:
        "An elegant carpet with 15 luxurious colours that are soft as velvet to the touch.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Harbour-Stone.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Caviar.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Clay.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Coast.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/DarkSand.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Dove.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Driftwood.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Firewood.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Gravel.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Platinum.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/raisin.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/rosemary.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Sterling.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Storm.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2022/12/Tawny.jpg",
      ],
    },
    {
      id: "31",
      name: "Vogue",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Bedroom-setting-600x600.png",
      description:
        "A soft and luxurious carpet that will elevate the comfort in any home.",
      colorOptions: [
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Acorn-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Farina-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Fresh-Rosemary-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Frosted-Glass-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Harvest-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Highland-Marsh-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Iron-Ore-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Magnesium-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Mystic-Grey-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Mystic-Stone-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Porcelain-1.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Royal-Blue.jpg",
        "https://signaturefloors.ie/wp-content/uploads/2024/05/Sugar-Cane-1.jpg",
      ],
    },
    {
      id: "32",
      name: "Boulevard Tweed",
      category: ["Carpet", "Plain Carpet"],
      image:
        "https://signaturefloors.ie/wp-content/uploads/2021/05/boulevard_tweed.jpg",
      description: "Ideal for heavy domestic/commercial spaces.",
      colorOptions: [
        "https://theinstallers.in/wp-content/uploads/2022/10/Best-Floor-Carpet-Designs.jpg", // Red
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrC0TN2Q97hbGx8A5T6hqtauflO_cTRNy43DR5GHFQDeGpNnET1tONWTnQNJ3wAtSKFaM&usqp=CAU", // Blue
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0IabW3rQ8yT5IRCMD72Ov2YBjAg9DgUXOmaaCQ7Wt870hCTKYLa63KLMDKTGNLjdNbhU&usqp=CAU", // Green
      ],
    },
  
    // Other products...
  ];

// Static category data
const categories = ["All", "Carpet","Firenze", "Plain Carpet","Revive"]

const Carpet = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  // Filter products based on selected category
  const filteredProducts =
    selectedCategory === "All"
      ? products
      : products.filter((product) => product.category.includes(selectedCategory));


  // Handle category change
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="container">
    {/* Category Filter Sidebar */}
    <div className="sidebar">
      <h2>Categories</h2>
      <ul>
        {categories.map((category, idx) => (
          <li
            key={idx}
            className={selectedCategory === category ? "active" : ""}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </li>
        ))}
      </ul>
    </div>

      {/* Product List */}
      <div className="product-list">
        <h2>Products</h2>
        <div className="grid">
          {filteredProducts.map((product) => (
            <div key={product.id} className="product-card">
              <Link to={`/category/${product.category}/${product.id}`}>
                <img src={product.image} alt={product.name} />
                <h3>{product.name}</h3>
                <p>{product.category.join(", ")}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carpet;
