import React from 'react';
import './AccessoriesPage.css';
// Import the custom CSS
import img1 from "../images/AccessoriesTiles/image.jpg";
import img2 from "../images/AccessoriesTiles/image1.png";
import img3 from "../images/AccessoriesTiles/image2.png";
import img4 from "../images/AccessoriesTiles/image3.jpg";
import img5 from "../images/AccessoriesTiles/image4.jpg";
import img6 from "../images/AccessoriesTiles/image5.png";
import img7 from "../images/AccessoriesTiles/image6.png";
import img8 from "../images/AccessoriesTiles/image7.jpg";

const accessories = [
  {
    title: 'Tile grout',
    description: 'Tile grout is essential for holding tiles in place, providing structural stability and preventing movement or shifting. It seals the gaps between tiles, protecting the underlying surface from moisture, dirt, and damage. Additionally, grout enhances the appearance of tiled areas, giving them a polished and finished look while simplifying cleaning and maintenance. ',
    image: img1,
  },
  {
    title: 'Tile cleaner',
    description: 'Tile cleaner is essential for removing dirt, grime, and stains, keeping tiled surfaces clean and hygienic. It helps preserve the tiles appearance by preventing discoloration and build-up that can dull their finish. Regular use of tile cleaner also reduces the risk of mold and mildew growth, ensuring a healthier and more aesthetically pleasing environment. ',
    image: img2,
  },
  {
    title: 'Tile primer',
    description: 'Tile primer is important for ensuring strong adhesion between tiles and the substrate, preventing tiles from loosening or shifting over time. It creates a smooth, even surface that enhances the bond between the adhesive and the tiles, improving overall installation quality. Using a tile primer also helps protect the underlying surface from moisture and damage, extending the lifespan of the tiled area.',
    image: img3,
  },
  {
    title: 'Tile sealant',
    description: 'Tile sealant is crucial for protecting tiles and grout from water damage, stains, and dirt buildup. It forms a protective layer that prevents moisture from penetrating, which helps reduce the growth of mold and mildew. Using tile sealant extends the lifespan of the installation, making it easier to maintain and preserving its aesthetic appeal. ',
    image: img4,
  },
  {
    title: 'Tile sealer',
    description: 'Tile sealer is important for protecting tiles and grout from moisture, stains, and dirt, preserving their appearance and functionality. It creates a protective barrier that reduces the growth of mold and mildew, especially in damp areas. Applying tile sealer enhances the durability and longevity of tiled surfaces, making them easier to clean and maintain over time.',
    image: img5,
  },
  {
    title: 'Tile adhesive',
    description: 'Tile adhesive is essential for securely bonding tiles to various surfaces, ensuring they stay in place and remain stable over time. It helps distribute weight evenly across the tiled surface, preventing cracks and shifting. Using the right adhesive improves the durability and longevity of the tile installation, making it resistant to moisture and other environmental factors.',
    image: img6,
  },
  {
    title: 'Tile trim',
    description: 'Tile trim is important for providing a clean, polished finish to the edges of tiled surfaces, creating a seamless and professional look. It protects the edges of tiles from damage, chips, and wear, extending the life of the installation. Additionally, tile trim helps hide uneven cuts and gaps, ensuring a neat and aesthetically pleasing appearance.',
    image: img7,
  },
  {
    title: 'Backer board',
    description: 'Backer board is crucial for providing a stable, moisture-resistant foundation for tile installations, especially in wet areas like bathrooms and kitchens. It enhances the durability of the tiled surface by preventing cracks and water damage to the underlying structure. Additionally, backer board helps improve tile adhesion, ensuring a secure and long-lasting installation.',
    image: img8,
  },
  
];

const AccessoriesPage = () => {
  return (
    <div className="accessories-container">
      <h1 className="accessories-title">Accessories</h1>
      <div className="accessories-list">
        {accessories.map((item, index) => (
          <div key={index} className="accessory-card">
            <div className="card-content">
              <h2 className="card-title">{item.title}</h2>
              <p className="card-description">{item.description}</p>
            </div>
            <div className="card-image-container">
              <img src={item.image} alt={item.title} className="card-image" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccessoriesPage;
