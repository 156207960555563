import React from "react";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import img1 from "../../images/hardwoods/18mm/vista_ultra.jpg";
// import img2 from "../../images/laminates/10mm/img2.jpg";

const Laminates10 = () => {
  const images = [
   
    {
      url: img1,
      text1: "Vista Ultra",
      text2: "18mm Hardwoods"

    },
    
    ];
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>18mm HardWoods</h1>
              Hardwood flooring is prized for its natural beauty and timeless appeal, adding warmth and sophistication to any space. It offers exceptional durability and can last for decades, with the ability to be sanded and refinished multiple times to restore its original charm. Hardwood improves indoor air quality by being free from allergens and synthetic materials, making it a healthier choice for homes. Its variety of wood species, grains, and finishes allows for customization to match diverse interior styles. Additionally, hardwood flooring increases property value, making it a valuable investment for homeowners.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}>
            <div className="text-overlay">
              <h2>{image.text1}</h2>
              <h3>{image.text2}</h3>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Laminates10;
