import React from "react";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";


import img2 from "../../images/Accessories/Trims & Profiles/3in1flat.jpg";
import img3 from "../../images/Accessories/Trims & Profiles/3in1reducer.jpg";
import img4 from "../../images/Accessories/Trims & Profiles/sa_angle.jpg";
// import img4 from "../images/laminates/10mm/img1.jpg";
// import img5 from "../images/laminates/10mm/img1.jpg";

const Trims = () => {
  const images = [
   
    {
      url: img2,
      text1: "3-in-1 Flat",
      text2: "Arbiton CS30 3-in-1 Flat"
    },
    {
      url:img3,
      text1: "3-in-1 Reducer",
      text2: "Arbiton CS37 3-in-1 Reducer"
    },
    {
      url:img4,
      text1: "S/A Angle",
      text2: "Arbiton CS25 Self Adhesive Angle"
    }
  ];
  return (
    <>
      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}>
            <div className="text-overlay">
              <h2>{image.text1}</h2>
              <h3>{image.text2}</h3>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Trims;
