import React from 'react';
import Home from "./components/Home.js";
import AboutUs from './components/AboutUs.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LivingRoomFloorTiles from './components/Tiles/FloorTiles/LivingRoomFloorTiles.js';
import BedroomFloorTiles from './components/Tiles/FloorTiles/BedroomFloorTiles.js';
import BathRoomFloorTiles from "./components/Tiles/FloorTiles/BathRoomFloorTiles.js";
import CommercialFloorTiles from './components/Tiles/FloorTiles/CommercialFloorTiles.js';
import KitchenFloorTiles from './components/Tiles/FloorTiles/KitchenFlootTiles.js';
import OutdoorFloorTiles from './components/Tiles/FloorTiles/OutdoorFloorTiles.js';

import OutdoorTiles from './components/Tiles/OutdoorTiles.js';
import ParkingTiles from './components/Tiles/ParkingTiles.js';

import Accessories from './components/Tiles/Accessories.js';

import LivingRoomWallTiles from './components/Tiles/WallTiles/WallTilesLivingRoom.js';
import BedRoomWallTiles from './components/Tiles/WallTiles/BedroomWallTiles.js';
import BathroomWallTiles from './components/Tiles/WallTiles/BathroomWallTiles.js';
import OfficesWallTiles from './components/Tiles/WallTiles/OfficeWallTiles.js';
import KitchenWallTiles from './components/Tiles/WallTiles/KitchenWallTiles.js';
import ExteriorWallTiles from './components/Tiles/WallTiles/ExteriorWallTiles.js';

import Layout from './components/Main.js';

import Laminates from './components/WoodFlooring/laminates/Laminates8mm.js';
import Laminates10 from './components/WoodFlooring/laminates/Laminates10mm.js';
import Laminates12 from './components/WoodFlooring/laminates/Laminates12mm.js';
import Laminates14 from './components/WoodFlooring/laminates/Laminates14mm.js';

import WRL8 from './components/WoodFlooring/WaterResisting/8mmwrl.js'
import WRL10 from './components/WoodFlooring/WaterResisting/10mmwrl.js'
import WRL12 from './components/WoodFlooring/WaterResisting/12mmwrl.js'

import WPS8 from './components/WoodFlooring/waterproofspc/8mmspc.js'
import WPS10 from './components/WoodFlooring/waterproofspc/10mmspc.js'
import WPS12 from './components/WoodFlooring/waterproofspc/12mmspc.js'
import StairNosings from './components/WoodFlooring/waterproofspc/stair_nosings.js';

import HardWoods8 from './components/WoodFlooring/Hardwoods/hardwood8mm.js'
import HardWoods10 from './components/WoodFlooring/Hardwoods/hardwood10mm.js'
import HardWoods12 from './components/WoodFlooring/Hardwoods/hardwood12mm.js'
import HardwoodsHerringbone from './components/WoodFlooring/Hardwoods/Hardwoods Herringbone.js';


import Underlays from './components/WoodFlooring/Accessories/Underlays.js';
import Maintenance from './components/WoodFlooring/Accessories/Maintenance.js';
import Trims from './components/WoodFlooring/Accessories/Trims & Profiles.js';

import Carpet from './components/Carpet/Carpet.js';
import CarpetPage from './components/Carpet/CarpetPage.js';

import BathroomRefurbishments from './components/Bathroom_Refurbishments/BathroomRefurbishment.js'
import Projects from './components/Projects/Projects.js';
function App() {
  return (
    
      <Router>
        <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          {/* Floor Tiles */}
          <Route path ="/Living_room" element = {<LivingRoomFloorTiles/>}/>
          <Route path = "/Bedroom" element = {<BedroomFloorTiles/>}/>
          <Route path = "/Bathroom" element = {<BathRoomFloorTiles />}/>
          <Route path = "/Commercial" element = {<CommercialFloorTiles />}/>
          <Route path = "/Kitchen" element = {<KitchenFloorTiles />}/>
          <Route path = "/Outdoor" element = {<OutdoorFloorTiles />} />
           {/* Wall Tiles */}
          <Route path = "/wall_tile_Living_room" element = {<LivingRoomWallTiles/>}/>
          <Route path = "/wall_tile_Bedroom" element = {<BedRoomWallTiles/>}/>
          <Route path = "/wall_tile_Kitchen" element = {<KitchenWallTiles/>}/>
          <Route path = "/wall_tile_Exterior" element = {<ExteriorWallTiles/>}/>
          <Route path = "/wall_tile_Bathroom" element = {<BathroomWallTiles/>}/>
          <Route path = "/wall_tile_offices" element = {<OfficesWallTiles/>}/>
          {/* Outdoor/ParkingTiles */}
          <Route path = "/outdoor_tiles" element = {<OutdoorTiles/>}/>
          <Route path = "/parking_tiles" element = {<ParkingTiles/>}/>
          <Route path = "/tile-accessories" element = {<Accessories/>}/>

          {/* Laminates */}
          <Route path = "/8mm_Laminate" element = {<Laminates />}/>
          <Route path = "/10mm_Laminate" element = {<Laminates10 />}/>
          <Route path = "/12mm_Laminate" element = {<Laminates12 />}/>
          <Route path = "/14mm_Laminate" element = {<Laminates14 />}/>
            {/* Water Resistance */}
            <Route path = "/8mm_wrl" element = {<WRL8 />}/>
          <Route path = "/10mm_wrl" element = {<WRL10 />}/>
          <Route path = "/12mm_wrl" element = {<WRL12 />}/>
          {/* Water Proof SPC */}
          <Route path = "/8mm_wps" element = {<WPS8 />}/>
          <Route path = "/10mm_wps" element = {<WPS10 />}/>
          <Route path = "/12mm_wps" element = {<WPS12 />}/>
          <Route path = "/stair_nosings" element = {<StairNosings  />} />
          {/* Hardwoods */}
          <Route path = "/8mm_hardwoods" element = {<HardWoods8 />}/>
          <Route path = "/10mm_hardwoods" element = {<HardWoods10 />}/>
          <Route path = "/12mm_hardwoods" element = {<HardWoods12 />}/>
          <Route path = "/hardwoods_herringbone" element = {<HardwoodsHerringbone/>}/>
          {/* Accessories */}
          <Route path = "/trims" element = {<Trims/>}/>
          <Route path = "/maintenance" element = {<Maintenance/>}/>
          <Route path = "/underlays" element = {<Underlays/>}/>

          <Route path = "/carpet" element = {<Carpet/>}/>
          <Route path="/category/:category/:productId" element={<CarpetPage />} />
          <Route path = "/bathroom_refurbishments" element = {<BathroomRefurbishments/>}/>
          <Route path = "/our_projects" element = {<Projects/>}/>
        </Routes>
        </Layout>
      </Router>
    
  );
}

export default App;