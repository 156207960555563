import React from "react";
import "../styles/WallTiles.css";

import img1 from "../images/OutdoorTiles/image.png";
import img2 from "../images/OutdoorTiles/image2.png";
import img3 from "../images/OutdoorTiles/image3.png";
import img4 from "../images/OutdoorTiles/image4.png";
// import img5 from "../images/OutdoorTiles/image4.png";
const OutdoorTiles = () => {
  return (
    <>
    
      <div className="tile">
        <div className="text">
        <div className="first">
        <p className="second">
          <h1 >Outdoor Tiles</h1>
          
          Outdoor tiles are important for providing a durable and weather-resistant surface that can withstand harsh environmental conditions like rain, sun, and temperature fluctuations. They offer slip resistance, ensuring safety in areas exposed to moisture, such as patios, walkways, and pool decks. Additionally, outdoor tiles enhance the aesthetic appeal of exterior spaces, offering a wide variety of designs that complement the overall landscaping.
          </p>
          <div >
          <img src={img1} alt="Placeholder" className="main_image"/>
        </div>
        </div>
      </div>
      </div>

      <div className="container">
      
      <div className="tile">
        <div className="image">
          <img src={img2} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img3} alt="Placeholder" />
        </div>
       
      </div>

      <div className="tile">
        <div className="image">
          <img src={img4} alt="Placeholder" />
        </div>
       
      </div>
      <div className="tile">
        {/* <div className="image">
          <img src={img5} alt="Placeholder" />
        </div> */}
       
      </div>
    </div></>
  );
};

export default OutdoorTiles;
