import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/floortiles/Commercial/image.png";
import img2 from "../../images/floortiles/Commercial/image2.png";
import img3 from "../../images/floortiles/Commercial/image3.png";
import img4 from "../../images/floortiles/Commercial/image4.png";
const CommercialFloorTiles = () => {
  return (
    <>
    
      <div className="tile1">
       
        <div className="text">
        <div className="first">
        <p className="second">
          <h1 >
            Floor Tiles for Commercial Purpose
          </h1>
         
            Our range of floor tiles is as huge as ever! It is an amalgamation
            of industrial proficiency and exquisite designs. Each tile is made
            with excellence using the most advanced equipment and technology to
            ensure great quality. These exceptional floor tiles are also best
            commercial spaces shopping malls, restaurant, fashion store, office
            reception, hotels etc.
          </p>
          <div>
          <img src={img1} alt="Placeholder" className="main_image" />
        </div>
        </div>
      </div>
      </div>

      <div className="container">
    
      <div className="tile ">
        <div className="image">
          <img src={img2} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img3} alt="Placeholder" />
        </div>
        
      </div>

      <div className="tile">
        <div className="image">
          <img src={img4} alt="Placeholder" />
        </div>
        
      </div>
      {/* <div className="tile">
        <div className="image">
          <img src={img5} alt="Placeholder" />
        </div>
        
      </div> */}
    </div></>
  );
};

export default CommercialFloorTiles;
