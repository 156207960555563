import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/walltiles/BedroomTiles/image5.jpg";
import img2 from "../../images/walltiles/BedroomTiles/image6.jpg";
import img3 from "../../images/walltiles/BedroomTiles/image7.jpg";
import img4 from "../../images/walltiles/BedroomTiles/image8.jpg";
import img5 from "../../images/walltiles/BedroomTiles/image9.jpg";

const BedroomWallTiles = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Bedroom Wall Tiles</h1>
              A chic and efficient way to refresh the look of your bedroom is by upgrading the walls with wall tiles. This simple change not only enhances the room's aesthetic appeal but also adds strength and durability. CFL offers a range of exceptionally stylish bedroom wall tiles, created with unmatched craftsmanship and advanced technology. These tiles are meticulously designed and versatile, allowing you to incorporate them in various creative ways.
            </p>
            <div >
              <img src={img1} alt="Bedroom Tile" className="main_image"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {[img2, img3, img4, img5].map((image, index) => (
          <div className="tile" key={index}>
            <div className="image">
              <img src={image} alt={`Bedroom Tile ${index}`} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BedroomWallTiles;
