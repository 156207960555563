import React from 'react';
import './Footer.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {  Phone } from 'lucide-react';
import { Mail } from 'lucide-react';
import logo1 from './images/whatsapp-icon.png';



const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        

        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/faq">FAQ</a></li>
            <li><a href="/testimonials">Testimonials</a></li>
            
          </ul>
        </div>

        <div className="footer-section">
          <h3>Follow Us</h3>
          <ul className="social-links">
            {/* <li><a href="#" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a></li>
            <li><a href="#" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} size="2x" />
            </a></li>
            <li><a href="#" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a></li> */}
            <li><a href="https://www.instagram.com/comfortfloorsandbathrooms/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a></li>
            </ul>
            <a
          href="https://maps.app.goo.gl/fKKoAhKrKsdcdDmbA"
          target="_blank"
          rel="noopener noreferrer"
          className="company-address1"
        >
          <div>
            <h6 className="address_title1">Address</h6>
            <p className="address1">Unit D1, Kylemore Factory Estate,</p>
            <p className="address1">Kylemore Rd, Kylemore, Dublin 10, D10 NV96</p>
          </div>
        </a>
         
        </div>

        <div className="footer-section">
          <h3>For Appointments, Please Contact</h3>
          <ul>
           
            <div className="contact_main1">
          <a href="tel:+353857139964" className="contact1">
            <Phone size={19} className="icon1" />
            <p className="mobile_num1"><b>+353 85 713 9964</b></p>
          </a>
          <a
            href="https://wa.me/353857139964"
            target="_blank"
            rel="noopener noreferrer"
            className="contact1"
          >
            <img src={logo1} alt="whatsapp-icon" width="30" height="30" />
            <p className="mobile_num1"><b>+353 85 713 9964</b></p>
          </a>
          <li>
          <a href="mailto:info@comfortfloors.ie" className="contact1">
            <Mail size={23} className="icon1" />
            <p className="mobile_num1"><b>info@comfortfloors.ie</b></p>
          </a>
         
            </li>
        </div>
           
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Comfort Floors and Bathrooms Limited  Company. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
