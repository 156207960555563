import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/walltiles/LivingRoomTiles/image5.jpg";
import img2 from "../../images/walltiles/LivingRoomTiles/image6.jpg";
// import img3 from "../../images/walltiles/LivingRoomTiles/image.png";
// import img4 from "../../images/walltiles/LivingRoomTiles/image.png";
// import img5 from "../../images/walltiles/LivingRoomTiles/image.png";

const WallTilesLivingRoom = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Wall Tiles for Living Rooms</h1>
              CFL offers an impressive collection of chic and sophisticated living room wall tiles, perfect for transforming a lackluster space into a stylish haven. Available in a stunning variety of colors, textures, sizes, and designs, these tiles are crafted using advanced manufacturing technologies to ensure excellence.
Design captivating patterns and striking visuals with these remarkable tiles, unleashing your creativity without bounds. Elevate the ambiance of your living room with tiles from Comfort Floor Tiles, adding a touch of elegance and refinement to your interiors.

            </p>
            <div >
              <img src={img1} alt="Living Room Tile" className="main_image"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {[img2].map((image, index) => (
          <div className="tile" key={index}>
            <div className="image">
              <img src={image} alt={`Living Room Tile ${index}`} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default WallTilesLivingRoom;
