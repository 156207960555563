import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import MenuBar from "./menu_bar";
import "./main.css"
// import HomeTabs from "./Home_Tabs"

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <MenuBar />
      <main className="content">
        {children}
      </main>
      {/* <HomeTabs /> */}
      <Footer />
    </>
  );
};

export default Layout;
