import React, { useState } from 'react';
import './Projects.css';
import { Parallax } from "react-parallax";
import "../styles/Parallax.css";
import img from './images/1.jpg'
import img1 from './images/2.jpg'
import img2 from './images/3.jpg'
import img3 from './images/4.jpg'
import img4 from './images/5.jpg'
import img5 from './images/6.jpg'
const Card = ({ videoName, onClick }) => {
  return (
    <div className="card" onClick={() => onClick(videoName)}>
      <video className="card-video">
        <source src={`/videos/${videoName}.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const App = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (videoName) => {
    setSelectedVideo(videoName);  // Show the selected video in full screen
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);  // Close the full-screen video
  };
  const images = [
    {
      url: img,
     
    },
    {
      url: img1,
     
    },
    {
      url: img2,
     
    },
    {
      url:img3,
     
    },
    {
      url:img4,
     
    },
    {
        url:img5,
       
      }
  ];
  return (
    <div className="app-container">
       <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
         
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}>
            {/* <div className="text-overlay">
              <h2>{image.text}</h2>
            </div> */}
          </div>
        </Parallax>
      ))}
    </div>
      <div className="cards-container">
        <Card videoName="4" onClick={handleVideoClick} />
        <Card videoName="5" onClick={handleVideoClick} />
        <Card videoName="6" onClick={handleVideoClick} />
        <Card videoName="7" onClick={handleVideoClick} />
        <Card videoName="8" onClick={handleVideoClick} />
        

      </div>

      {selectedVideo && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <video className="modal-video" autoPlay>
              <source src={`/videos/${selectedVideo}.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button className="close-btn" onClick={handleCloseModal}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
