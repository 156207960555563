import React  from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Carousel from './carousel';
function App() {
  
  return (
    <div>
        <Carousel />
    </div>
  );
}

export default App;


