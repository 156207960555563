import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/waterproofspc/Tiger.jpg";
// import img2 from "../../images/laminates/8mm/img2.jpg";
// import img3 from "../../images/laminates/8mm/img3.jpg";
// import img4 from "../../images/laminates/8mm/img4.jpg";
// import img5 from "../../images/laminates/8mm/img5.jpg";
const Laminates = () => {
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>Tiger 6mm</h1>
              Waterproof SPC (Stone Plastic Composite) flooring is a game-changer for moisture-prone 
              areas, as it is 100% waterproof and impervious to water damage. Its rigid core construction
              provides exceptional durability and stability, resisting warping, cracking, and buckling even 
              in extreme conditions. SPC flooring combines the beauty of wood-like designs with unparalleled 
              practicality, making it suitable for bathrooms, kitchens, and basements. Easy to install with 
              click-and-lock systems, it also requires minimal maintenance, saving time and effort in cleaning. 
              Moreover, it is highly scratch-resistant and pet-friendly, offering a long-lasting and cost-effective
              flooring solution for households and commercial spaces alike.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="tile">
          <div className="image">
            <img src={img2} alt="Laminate Tile 2" />
          </div>
        </div>

        <div className="tile">
          <div className="image">
            <img src={img3} alt="Laminate Tile 3" />
          </div>
        </div>

        <div className="tile">
          <div className="image">
            <img src={img4} alt="Laminate Tile 4" />
          </div>
        </div>

        <div className="tile">
          <div className="image">
            <img src={img5} alt="Laminate Tile 5" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Laminates;
