import React from "react";
import "../styles/WallTiles.css";

import img1 from "../images/ParkingTiles/image.jpg";
import img2 from "../images/ParkingTiles/image1.jpg";
import img3 from "../images/ParkingTiles/image2.jpg";
import img4 from "../images/ParkingTiles/image3.jpg";
import img5 from "../images/ParkingTiles/image4.jpg";
const OutdoorTiles = () => {
  return (
    <>
    
      <div className="tile">
        <div className="text">
        <div className="first">
        <p className="second">
          <h1 >Parking Tiles</h1>
          
          Parking tiles are important for providing a durable, high-strength surface that can withstand the weight of vehicles and constant traffic. They are designed to be slip-resistant, offering better traction and safety for both vehicles and pedestrians. These tiles are resistant to weathering, stains, and oil spills, making them ideal for outdoor use in parking areas. Additionally, parking tiles enhance the aesthetic appeal of parking lots by offering a clean, organized look while being low-maintenance and easy to clean.
          </p>
          <div >
          <img src={img1} alt="Placeholder" className="main_image"/>
        </div>
        </div>
      </div>
      </div>

      <div className="container">
      
      <div className="tile">
        <div className="image">
          <img src={img2} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img3} alt="Placeholder" />
        </div>
       
      </div>

      <div className="tile">
        <div className="image">
          <img src={img4} alt="Placeholder" />
        </div>
       
      </div>
      <div className="tile">
        <div className="image">
          <img src={img5} alt="Placeholder" />
        </div>
       
      </div>
    </div></>
  );
};

export default OutdoorTiles;
