import React from "react";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import img1 from "../../images/laminates/10mm/img1.jpg";
import img2 from "../../images/laminates/10mm/img2.jpg";
import img3 from "../../images/laminates/10mm/10mm.jpeg";
import img4 from "../../images/laminates/10mm/10mm 2.jpg";
import img5 from "../../images/laminates/10mm/10mm 3.jpg";

const Laminates10 = () => {
  const images = [
    {
      url: img1,
      text: "Majestic"
    },
    {
      url: img2,
      text: "Mood"
    },
    {
      url:img3,
      text: "Krono Marine"
    },
    {
      url:img4,
      text: "Krono Aroma "
    },
    {
        url:img5,
        text: "Xtreme Wide & Long"
      }
    ];
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>10mm Laminates</h1>
              Laminate wood flooring is a cost-effective alternative to
              hardwood, offering the look of natural wood at a fraction of the
              price. It is highly durable and resistant to scratches, stains,
              and moisture, making it ideal for high-traffic areas and homes
              with pets or children. Easy to install with click-and-lock
              systems, it reduces labor costs and installation time. Laminate
              flooring requires minimal maintenance and is simple to clean,
              making it a practical choice for busy households. Its wide range
              of designs, colors, and textures ensures it can mimic various wood
              types, offering versatility for different interior styles.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}>
            <div className="text-overlay">
              <h2>{image.text}</h2>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Laminates10;
