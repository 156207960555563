import React from "react";
import "../../styles/WallTiles.css";

import img1 from "../../images/floortiles/outdoor/image.png";
import img2 from "../../images/floortiles/outdoor/image2.png";
import img3 from "../../images/floortiles/outdoor/image3.png";
import img4 from "../../images/floortiles/outdoor/image4.png";

const OutdoorFloorTiles = () => {
  return (
    <>
   
      <div className="tile1">
        <div className="text">
        <div className="first">
        <p className="second">
          <h1 >Outdoor Floor Tiles</h1>
         
            CFL is known for its exclusive range of ceramic tiles in Ireland
            including many tremendous variants, anti-slip floor tiles being the
            most used and innovative ones. These floor tiles have been crafted
            to meet your need, style and most importantly the safety
            requirement. Our wide-ranging collection features various finishes
            that will allow you to add that extra zing to your space. The
            collection of Anti-slip tiles comes with a good quality and assured
            safety. With in-depth research and innovation, we’ve have created a
            product which is best used for bathrooms, kitchens, ramps, swimming
            pools etc.
          </p>
          <div >
          <img src={img1} alt="Placeholder" className="main_image" />
        </div>
        </div>
      </div>
      </div>

      <div className="container">
      {/* <div className="tile ">
        <div className="image">
          <img src={img1} alt="Placeholder" />
        </div>
      </div> */}
      <div className="tile ">
        <div className="image">
          <img src={img2} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img3} alt="Placeholder" />
        </div>
      </div>

      <div className="tile">
        <div className="image">
          <img src={img4} alt="Placeholder" />
        </div>
      </div>
    </div></>
  );
};

export default OutdoorFloorTiles;
