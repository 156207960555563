import React from "react";
import "../../styles/WallTiles.css";
import { Parallax } from "react-parallax";
import "../../styles/Parallax.css";
import img1 from "../../images/laminates/8mm/img1.jpg";
import img2 from "../../images/laminates/8mm/img2.jpg";
import img3 from "../../images/laminates/8mm/img3.jpg";
import img4 from "../../images/laminates/8mm/img4.jpg";
import img5 from "../../images/laminates/8mm/img5.jpg";
import img6 from "../../images/laminates/8mm/1.jpg";
import img7 from "../../images/laminates/8mm/krono 8mm.jpg";
import img8 from "../../images/laminates/8mm/krono 8mm 3.jpg";
const Laminates = () => {
  const images = [
   
    // {
    //   url: img2,
    //   text: "8mm Laminate"
    // },
    {
      url:img1,
      text: "Effect"
    },
    {
      url:img2,
      text: "Liberty Xpressions"
    },
    {
        url:img3,
        text: "Natura Line"
      },
      {
        url:img4,
        text: "Noblesse"
      },
      {
        url:img5,
        text: "Noblesse"
      },
      {
        url:img6,
        text: "Krono Sigma"
      },
      {
        url:img7,
        text: "Krono Exclusive"
      },
      {
        url:img8,
        text: "Krono Testa"
      }
    ];
  return (
    <>
      <div className="tile1">
        <div className="text">
          <div className="first">
            <p className="second">
              <h1>8mm Laminates</h1>
              Laminate wood flooring is a cost-effective alternative to
              hardwood, offering the look of natural wood at a fraction of the
              price. It is highly durable and resistant to scratches, stains,
              and moisture, making it ideal for high-traffic areas and homes
              with pets or children. Easy to install with click-and-lock
              systems, it reduces labor costs and installation time. Laminate
              flooring requires minimal maintenance and is simple to clean,
              making it a practical choice for busy households. Its wide range
              of designs, colors, and textures ensures it can mimic various wood
              types, offering versatility for different interior styles.
            </p>
            <div>
              <img src={img1} alt="Laminate Tile 1" className="main_image" />
            </div>
          </div>
        </div>
      </div>

      <div>
      {images.map((image, index) => (
        <Parallax
          key={index}
          bgImage={image.url}
          strength={300}
          
          bgImageStyle={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={`section section-${index + 1}`}>
            <div className="text-overlay">
              <h2>{image.text}</h2>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
    </>
  );
};

export default Laminates;
